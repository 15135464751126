<template>
    <div class="container-fluid p-0">
        <div class="nav-bar d-flex align-items-center">
            <router-link to="/"><img src="@/assets/images/logo.png" alt="555 Network" class="logo"></router-link>
            <nav class="d-flex flex-wrap">
                <router-link to="/">Home</router-link>
                <router-link to="/about">About</router-link>
                <router-link to="/model">555 Model</router-link>
                <router-link to="/training">Training</router-link>
                <router-link to="/video">Video</router-link>
                <router-link to="/audio">Audio</router-link>
                <router-link to="/contact">Contact Us</router-link>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderView"
    }
</script>

<style scoped>
    .nav-bar {
        background-image: url('@/assets/images/head_img.png');
        background-color: #6b6e2c;
        background-size: cover;
        background-position: center;
        padding: 5px 40px;
    }

    .nav-bar a {
        font-size: 20px;
        color: white;
        font-weight: bold;
        text-decoration: none;
        margin-right: 20px;
    }

    .nav-bar a:hover {
        text-decoration: underline;
    }

    .logo {
        height: 70px;
        margin-right: 100px;
    }

    @media (max-width: 768px) {
        .nav-bar {
            flex-direction: column;
            align-items: flex-start;
        }

        .nav-bar a {
            margin-bottom: 10px;
        }
    }
</style>