<template>
  <HeaderView/>
  <router-view/>
  <FooterView/>
</template>
<script>
  import HeaderView from "@/components/includes/HeaderView";
  import FooterView from "@/components/includes/FooterView";
  export default {
    name: "App",
    components:{HeaderView, FooterView}
  }
</script>
<style scoped>
  .section {
    background-color: #f9f9f9;
  }
</style>
