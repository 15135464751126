<template>
    <footer class=" bg-dark">
        <div class="container">
            <div class="row">
                <div class="col">
                    <p class="text-center text-white">Developed By: 555Network</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterView"
    }
</script>

<style scoped>

</style>